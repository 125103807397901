import React, {
  useState, useEffect, useMemo,
} from 'react';
import {
  format,
  startOfDay,
  eachDayOfInterval,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import colors from '../../constants';
import { evalCondition } from '../../utils';
import conditions from './conditions.json';
import LineChart from '../LineChart';
import BarChart from '../BarChart';
import styles from './dashboard-environment.module.scss';

const DashboardEnvironment = ({
  observations = [],
  list,
  range,
}) => {
  const [generalData, setGeneralData] = useState();
  const [bowlsData, setBowlsData] = useState();

  const filteredObservations = useMemo(() => (
    observations.filter((observation) => observation.domain === 'environment')
  ), [observations]);

  useEffect(() => {
    if (filteredObservations?.length === 0) {
      setGeneralData();
      setBowlsData();
      return;
    }

    const bowls = {};
    const environmentData = {
      acoustic: {},
      canFastSwim: {},
      canJump: {},
      optingOut: {},
    };
    filteredObservations.forEach((observation) => {
      const date = format(new Date(observation.observationDate), 'dd-MM-yyyy', { locale: fr });
      // const date = format(new Date(observation.observationDate), 'dd-MM-yyyy', { locale: fr });
      // eslint-disable-next-line max-len
      if (!environmentData.acoustic[date] || environmentData.acoustic[date].observationDate < observation.observationDate) {
        const labelValue = observation.acoustic ? 'Présence' : 'Absence';
        environmentData.acoustic[date] = {
          date,
          labelValue,
          value: observation.acoustic ? 1 : 0.5,
          color: observation.acoustic ? colors.bad : colors.good,
          observationDate: observation.observationDate,
        };
      }
      // eslint-disable-next-line max-len
      if (!environmentData.canFastSwim[date] || environmentData.canFastSwim[date].observationDate < observation.observationDate) {
        const labelValue = observation.canFastSwim ? 'Oui' : 'Non';
        environmentData.canFastSwim[date] = {
          date,
          labelValue,
          value: observation.canFastSwim ? 0.5 : 1,
          color: observation.canFastSwim ? colors.good : colors.bad,
          observationDate: observation.observationDate,
        };
      }
      // eslint-disable-next-line max-len
      if (!environmentData.canJump[date] || environmentData.canJump[date].observationDate < observation.observationDate) {
        const labelValue = observation.canJump ? 'Oui' : 'Non';
        environmentData.canJump[date] = {
          date,
          labelValue,
          value: observation.canJump ? 0.5 : 1,
          color: observation.canJump ? colors.good : colors.bad,
          observationDate: observation.observationDate,
        };
      }

      if (!environmentData.optingOut[date]
          || environmentData.optingOut[date].observationDate < observation.observationDate) {
        const result = evalCondition(conditions.optingOut, observation.bowls?.length || 0);
        environmentData.optingOut[date] = {
          date,
          name: 'Nombre de bassin',
          // labelValue,
          value: observation.bowls.length,
          labelValue: observation.bowls.length === 3 ? `3 ou plus` : observation.bowls.length,
          color: colors?.[result] || colors.bad,
          observationDate: observation.observationDate,
        };
      }

      observation.statementBowls
        .filter(({ bowl }) => list.bowls.find((opt) => opt.value === bowl))
        .forEach(({
          bowl,
          temperature,
          ph,
          salinity,
          combinedChlorine,
          freeChlorine,
          coliforms,
        }) => {
          const item = bowls[bowl] || {
            temperature: {},
            salinity: {},
            ph: {},
            combinedChlorine: {},
            freeChlorine: {},
            coliforms: {},
          };

          // HANDLE SET 'temperature' WITH THE MOST RECENT VALUE OF THE DATE
          if ((typeof temperature === 'number')
            && (!item.temperature[date]
              || item.temperature[date].observationDate < observation.observationDate)) {
            item.temperature[date] = {
              date, value: temperature, observationDate: observation.observationDate, unit: '°', name: 'Température',
            };
          }

          // HANDLE SET 'salinity' WITH THE MOST RECENT VALUE OF THE DATE
          if ((typeof salinity === 'number')
            && (!item.salinity[date]
            || item.salinity[date].observationDate < observation.observationDate)) {
            item.salinity[date] = {
              date, value: salinity, observationDate: observation.observationDate, unit: 'ppt', name: 'Salinité',
            };
          }

          // HANDLE SET 'combinedChlorine' WITH THE MOST RECENT VALUE OF THE DATE
          if ((typeof combinedChlorine === 'number')
            && (!item.combinedChlorine[date]
              || item.combinedChlorine[date].observationDate < observation.observationDate)
          ) {
            item.combinedChlorine[date] = {
              date,
              value: combinedChlorine,
              observationDate: observation.observationDate,
              unit: 'ppm',
              name: 'Chlore combiné',
            };
          }

          // HANDLE SET 'freeChlorine' WITH THE MOST RECENT VALUE OF THE DATE
          if ((typeof freeChlorine === 'number')
            && (!item.freeChlorine[date]
              || item.freeChlorine[date].observationDate < observation.observationDate)
          ) {
            item.freeChlorine[date] = {
              date, value: freeChlorine, observationDate: observation.observationDate, unit: 'ppm', name: 'Chlore libre',
            };
          }

          // HANDLE SET 'ph' WITH THE MOST RECENT VALUE OF THE DATE
          if ((typeof ph === 'number') && (!item.ph[date]
          || item.ph[date].observationDate < observation.observationDate)
          ) {
            item.ph[date] = {
              date, value: ph, observationDate: observation.observationDate, unit: 'pH', name: 'pH',
            };
          }

          // HANDLE SET 'coliforms' WITH THE MOST RECENT VALUE OF THE DATE
          if (coliforms && (!item.coliforms[date]
          || item.coliforms[date].observationDate < observation.observationDate)
          ) {
            const labelValue = list.coliformsStates.find((opt) => opt.value === coliforms)?.label || '---';

            let value = 0;
            if (coliforms === 'good') value = 0.5;
            if (coliforms === 'bad') value = 1;
            const color = coliforms === 'good' ? colors.good : colors.bad;

            item.coliforms[date] = {
              date,
              labelValue,
              value,
              color,
              observationDate: observation.observationDate,
            };
          }

          bowls[bowl] = item;
        });
    });

    // console.log(JSON.parse(JSON.stringify(environmentData.optingOut)));

    const start = startOfDay(range.start);
    const end = startOfDay(range.end);
    let dates = eachDayOfInterval({ start, end });
    dates = dates.map((date) => format(date, 'dd-MM-yyyy', { locale: fr }));

    // COMPUTE ENVIRONMENT DATA FOR CHARTS
    Object.entries(environmentData).forEach(([criteria, data]) => {
      const BarCharts = ['acoustic', 'canFastSwim', 'canJump', 'optingOut'];
      if (BarCharts.includes(criteria)) {
        environmentData[criteria] = [];
        const arrData = Object.values(data);
        dates.forEach((d) => {
          const find = arrData.find((k) => k.date === d);
          if (find) {
            environmentData[criteria].push(find);
          }
        });
      } else {
        delete environmentData[criteria];
      }
    });

    // COMPUTE BOWL DATA FOR CHARTS
    Object.entries(bowls).forEach(([bowl, bowlData]) => {
      Object.entries(bowlData).forEach(([key, data]) => {
        bowls[bowl][key] = [];
        Object.values(data).forEach((d) => {
          let { color } = d;
          if (conditions[key]) {
            const result = evalCondition(conditions[key], d.value);
            color = colors?.[result] || colors.bad;
          }
          bowls[bowl][key].push({
            ...d,
            color,
          });
        });
      });
    });

    setGeneralData(environmentData);
    setBowlsData(bowls);
  }, [filteredObservations]);

  const renderCharts = (data) => (
    <div className={styles.containerCharts}>
      <div className={styles.chart}>
        <h3>Coliformes</h3>
        <BarChart domain={[0, 1]} hideYAxis data={data.coliforms} key="coliforms" />
      </div>
      <div className={styles.chart}>
        <h3>Confort thermique</h3>
        <LineChart data={data.temperature} unit={data?.temperature[0]?.unit} />
      </div>
      <div className={styles.chart}>
        <h3>PH</h3>
        <LineChart data={data.ph} unit={data?.ph[0]?.unit} axisYNotUnit />
      </div>
      <div className={styles.chart}>
        <h3>Salinité</h3>
        <LineChart data={data.salinity} unit={data?.salinity[0]?.unit} />
      </div>
      <div className={styles.chart}>
        <h3>Chlore combiné</h3>
        <LineChart data={data.combinedChlorine} unit={data?.combinedChlorine[0]?.unit} />
      </div>
      <div className={styles.chart}>
        <h3>Chlore total</h3>
        <LineChart data={data.freeChlorine} unit={data?.freeChlorine[0]?.unit} />
      </div>
    </div>
  );

  return (
    <>
      {generalData && (
        <div className={styles.general}>
          {generalData.acoustic && (
            <div className={styles.chart}>
              <h3>Acoustique</h3>
              <BarChart domain={[0, 1]} hideYAxis data={generalData.acoustic} />
            </div>
          )}
          {generalData.canFastSwim && (
            <div className={styles.chart}>
              <h3>Facilité de déplacement<br />(possibilité de sauter)</h3>
              <BarChart domain={[0, 1]} hideYAxis data={generalData.canFastSwim} />
            </div>
          )}
          {generalData.canJump && (
            <div className={styles.chart}>
              <h3>Facilité de déplacement<br />(possibilité de nager vite)</h3>
              <BarChart domain={[0, 1]} hideYAxis data={generalData.canJump} />
            </div>
          )}
          {generalData.optingOut && (
            <div className={styles.chart}>
              <h3>Possibilité de se soustraire</h3>
              <BarChart domain={[0, 3]} hideYAxis data={generalData.optingOut} />
            </div>
          )}
        </div>
      )}
      {bowlsData && (
        <>
          {Object.entries(bowlsData)
            .sort((([bowlA], [bowlB]) => bowlA.localeCompare(bowlB))).map(([bowl, data]) => (
              <React.Fragment key={`bowl-${bowl}`}>
                <h3 className={styles.title}>{bowl}</h3>
                <div className={styles.bowl}>
                  {renderCharts(data)}
                </div>
              </React.Fragment>
            ))}
        </>
      )}
    </>
  );
};
export default DashboardEnvironment;
