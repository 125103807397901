import {
  getData,
  putData,
} from './index';

import {
  SPECIE_GET,
  SPECIE_LIST_GET,
  SPECIE_PUT,
  SPECIE_LOADING,
  SPECIE_ERROR,
} from './types';

const getSpecie = async (dispatch, id) => {
  const url = `/species/${id}`;
  dispatch({
    type: SPECIE_LOADING,
    payload: SPECIE_GET,
  });
  const response = await getData(SPECIE_ERROR, url, dispatch, true);
  if (response.data?.specie) {
    dispatch({
      type: SPECIE_GET,
      payload: response.data?.specie,
    });
  }
};

const getSpecieList = async (dispatch) => {
  const url = '/species/list';
  dispatch({
    type: SPECIE_LOADING,
    payload: SPECIE_LIST_GET,
  });
  const response = await getData(SPECIE_ERROR, url, dispatch, true);
  if (response.data?.list) {
    dispatch({
      type: SPECIE_LIST_GET,
      payload: response.data?.list,
    });
  }
};

const updateSpecie = async (dispatch, data) => {
  const { _id, ...updatedSpecie } = data;
  const url = `/species/${_id}`;
  dispatch({
    type: SPECIE_LOADING,
    payload: `${SPECIE_PUT}-${_id}`,
  });
  const response = await putData(SPECIE_ERROR, url, dispatch, updatedSpecie, true);
  if (response.data?.specie) {
    dispatch({
      type: SPECIE_PUT,
      payload: response.data,
    });
  }
  return response;
};

export {
  getSpecie,
  getSpecieList,
  updateSpecie,
};
