import React, {
  useState,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { formatISO } from 'date-fns';
import {
  getObservations,
  getObservationList,
  getObservationStatsDomains,
} from '../../actions/observations';
import { getAnimals } from '../../actions/animals';
import { getSpecie, getSpecieList } from '../../actions/species';
import StyledSelect from '../../lib/HooksFormFields/StyledSelect/Select';
import styles from './data.module.scss';
import Loader from '../../components/Loader';
import SelectDateRange from '../../components/SelectDateRange';
import { OBSERVATIONS_GET } from '../../actions/types';
import DashboardEnvironment from '../../components/DashboardEnvironment';
import DashboardHealth from '../../components/DashboardHealth';
import DashboardNutrition from '../../components/DashboardNutrition';
import DashboardBehavior from '../../components/DashboardBehavior';

const optionsRange = [
  { label: 'Mois', value: 'month' },
  { label: 'Semaine', value: 'week' },
];

const Data = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const {
    authReducer,
    animalReducer,
    specieReducer,
    observationReducer,
  } = useSelector((store) => store);
  const { user } = authReducer;
  const { animals } = animalReducer;
  const { list, observations, isLoading } = observationReducer;
  const { list: listSpecies, specie } = specieReducer;
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [domain, setDomain] = useState();

  const { control: controlSpecie, watch } = useForm();
  const { control, watch: watchFilter } = useForm();
  const specieWatch = watch('specie');
  const watchView = watchFilter('view');

  useEffect(() => {
    getSpecieList(dispatch);
    getObservationList(dispatch);
  }, []);

  // useEffect(() => {
  //   const container = document.querySelector('#layout>div:nth-child(2)');
  //   console.log(container);
  //   container.addEventListener('scroll', (e) => {
  //     setOffsetX(e.currentTarget.scrollLeft);
  //   });
  // }, []);

  useEffect(() => {
    if (!specie) return;
    localStorage.setItem('specie', specie._id);
    getAnimals(dispatch, { specie: specie._id });
  }, [specie?._id]);

  useEffect(() => {
    if (!dateRange.start || !dateRange.end || animals.length === 0) return;
    const currentDomain = searchParams.get('domain') || list?.domains?.[0]?.value || '';

    const query = {
      domain: currentDomain === 'behavior' ? 'behavior,restraint,enrichment,wtp' : currentDomain,
    };
    // if (!currentDomain === 'nutrition') {
    //   query.start = dateRange.start;
    //   query.end = dateRange.end;
    // }

    query.start = formatISO(dateRange.start);
    query.end = formatISO(dateRange.end);
    getObservations(dispatch, animals, query);
    setDomain(currentDomain);
  }, [
    animals,
    searchParams,
    list.domains,
    dateRange.start,
    dateRange.end,
  ]);

  // GET SPECIE
  useEffect(() => {
    if (specieWatch?.value) getSpecie(dispatch, specieWatch?.value);
    else if (!specieWatch?.value && list?.species) {
      getSpecie(dispatch, list?.species[0].value);
    }
  }, [specieWatch?.value, list?.species]);

  useEffect(() => {
    if (!specie) return;
    localStorage.setItem('specie', specie._id);
    getObservationStatsDomains(dispatch, { specie: specie._id });
  }, [specie?._id]);

  const handleSetRange = (startDate, endDate) => {
    // Check if end date is in the past
    const adjustedEndDate = endDate.getTime() < Date.now() ? endDate : new Date();

    setDateRange({
      start: startDate,
      end: adjustedEndDate,
    });
  };

  const handleSelectDomain = (d) => {
    const params = new URLSearchParams(searchParams);
    params.set('domain', d);
    setSearchParams(params);
    setDomain(null);
  };

  return (
    <div className={styles.data}>
      <div className={styles.header}>
        <h1>Bonjour {`${user.profile.firstName}`} !</h1>
        <div className={styles.filter}>
          <SelectDateRange
            range={watchView?.value}
            handleSetRange={(start, end) => handleSetRange(start, end)}
          />
          <div className={styles.selectView}>
            <StyledSelect
              name="view"
              control={control}
              defaultValue={optionsRange[0]}
              options={optionsRange}
            />
          </div>
          <div className={styles.selectSpecies}>
            { listSpecies?.species && (
            <StyledSelect
              name="specie"
              control={controlSpecie}
              defaultValue={listSpecies?.species[0]}
              options={listSpecies?.species}
            />
            )}
          </div>
        </div>
      </div>
      <nav>
        {list.domains?.length > 0 && (
        <ul>
          {list.domains?.map((d) => (
            <li
              key={`domain-${d.value}`}
              className={domain === d.value ? styles.selected : ''}
              onClick={() => handleSelectDomain(d.value)}
            >
              {d.label}
            </li>
          ))}
        </ul>
        )}
      </nav>
      {isLoading.includes(OBSERVATIONS_GET) ? (
        <Loader small />
      ) : (
        <>
          {domain === 'nutrition' && (
            <DashboardNutrition
              list={list}
              animals={animals}
              observations={observations}
              range={dateRange}
            />
          )}
          {domain === 'health' && (
            <DashboardHealth list={list} observations={observations} />
          )}
          {domain === 'environment' && (
          <DashboardEnvironment
            list={list}
            observations={observations}
            range={dateRange}
          />
          )}
          {animals.length > 0 && domain === 'behavior' && (
          <DashboardBehavior
            animals={animals}
            observations={observations}
            list={list}
            dateRange={dateRange}
          />
          )}
        </>
      )}
    </div>
  );
};

export default Data;

// {
//   b2: [
//     { date: ..., value ...}
//   ]
// }
