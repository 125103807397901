import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';

import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useWatch } from 'react-hook-form';
import { BsCalendar } from 'react-icons/bs';
// GrFormView

import styles from './datepicker.module.scss';
import './datepicker.scss';

registerLocale('fr', fr);

const StyledDatePicker = forwardRef(({
  name,
  control,
  required,
  rules,
  placeholder = '',
  widthLabel = null,
  inline = false,
  label = null,
  className,
  defaultValue = null,
  handleChange = null,
  dateFormat = 'dd/MM/yyyy',
}, ref) => {
  const inputWatch = useWatch({
    control,
    name,
  });

  const [date, setDate] = useState();

  useEffect(() => {
    setDate(inputWatch);
  }, [inputWatch]);

  useImperativeHandle(ref, () => ({
    getValue: () => date,
    getObject: () => ({ [name]: date }),
  }));

  function isSameDay(date1, date2) {
    if (
      date1 && date2
      && date1.getDate() === date2.getDate()
      && date1.getMonth() === date2.getMonth()
      && date1.getFullYear() === date2.getFullYear()
    ) {
      return true;
    } return false;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules || (required ? {
        required: 'Ce champs date est obligatoire',
      } : {})}
      render={({
        field: {
          // name,
          value,
          ref: inputRef,
          onChange,
          onBlur,
        },
      }) => (
        <div className={inline ? `container-custom-date-picker ${styles['date-picker']} ${styles.inline}` : `container-custom-date-picker ${styles['date-picker']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <div className={`${styles['container-input']}${className ? ` ${styles[className]}` : ''}`}>
            <DatePicker
              name={name}
              onBlur={() => onBlur()}
              selected={value}
              onChange={(dateValue) => {
                onChange(dateValue);
                if (handleChange) handleChange(dateValue);
              }}
              locale="fr"
              dateFormat={dateFormat}
              placeholderText={placeholder}
              popperPlacement="bottom-center"
              customInput={<Input ref={inputRef} />}
              dayClassName={(d) => (isSameDay(d, value) ? `${styles.day} ${styles.selected}` : `${styles.day}`)}
            />
            <div className={styles['container-icon']}>
              <BsCalendar />
            </div>
          </div>
        </div>
      )}
    />
  );
});

const Input = forwardRef(({
  onChange,
  placeholder,
  value,
  id,
  onClick,
}, ref) => (
  <input
    className={styles.input}
    ref={ref}
    onChange={onChange}
    onKeyDown={(e) => {
      e.preventDefault();
      return false;
    }}
    placeholder={placeholder}
    value={value}
    id={id}
    onClick={onClick}
    size={value?.length ? value.length - 1 : 0}
  />
));

StyledDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
};

export default StyledDatePicker;
