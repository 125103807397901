import React from 'react';
import styles from './layout.module.scss';
import Sidebar from '../Sidebar';

const Layout = ({ children }) => (
  <div className={styles.layout} id="layout">
    <Sidebar />
    <div className={styles.container}>
      {children}
    </div>
  </div>
);

export default Layout;
