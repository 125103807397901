import React, { useCallback } from 'react';
import styles from './domains-stats.module.scss';
import IconNutrition from '../../assets/images/icons-domain/icon-nutrition.svg';
import IconHealth from '../../assets/images/icons-domain/icon-health.svg';
import IconEnvironment from '../../assets/images/icons-domain/icon-environment.svg';
import IconBehavior from '../../assets/images/icons-domain/icon-behavior.svg';

const DomainStats = ({ stats, title, domain }) => {
  const getIcon = useCallback(() => {
    let icon = IconBehavior;
    switch (domain) {
      case 'health':
        icon = IconHealth;
        break;
      case 'nutrition':
        icon = IconNutrition;
        break;
      case 'environment':
        icon = IconEnvironment;
        break;
      default:
        icon = IconBehavior;
        break;
    }
    return <img src={icon} alt={domain} />;
  }, [domain]);

  const renderStats = useCallback(() => {
    const total = Object.values(stats).reduce((t, d) => t + d, 0);
    if (total === 0) {
      return (
        <div className={styles.empty} style={{ width: '100%' }} />
      );
    }
    const elements = Object.entries(stats)
      .filter(([, value]) => value > 0)
      .map(([key, value]) => {
        const pourcentage = (value * 100) / total;
        return (
          <div key={`${domain}-${key}`} className={styles[key]} style={{ width: `${pourcentage}%` }}>
            <p>{value}</p>
          </div>
        );
      });
    return elements;
  }, [stats]);

  return (
    <div className={styles.domainStats}>
      {getIcon()}
      <h4>{title}</h4>
      <div className={styles.stats}>
        {renderStats()}
      </div>
    </div>
  );
};

export default DomainStats;
