import React, {
  useRef, useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useController, useWatch } from 'react-hook-form';
import styles from './textarea.module.scss';

const Textarea = forwardRef(({
  name,
  control,
  rules = {},
  widthLabel = null,
  inline = false,
  disabled = false,
  label = null,
  placeholder = '',
  defaultValue,
  maxlength = null,
  className,
  // eslint-disable-next-line no-unused-vars
  ...rest
}, ref) => {
  const [textareaValue, setTextareaValue] = useState('');
  const [style, setStyle] = useState({});
  const textareaRef = useRef();
  const textareaWatch = useWatch({
    control,
    name,
  });

  const {
    field: {
      value = '',
      ref: refFromController,
      onChange,
    },
    formState: { isValid },
  } = useController({
    control,
    name,
    rules,
    defaultValue,
  });

  function autosize() {
    const elt = textareaRef.current.querySelector('textarea');
    if (!elt) return;
    elt.style.cssText = 'height:auto; padding:0';
    elt.style.cssText = `height:${elt.scrollHeight}px`;
    setStyle({
      padding: '10px',
      height: `${elt.scrollHeight}px`,
    });
  }

  function getClassName() {
    let classValue = styles['container-textarea'];
    if (className) {
      classValue += ` ${styles[className]}`;
    }
    if (inline) {
      classValue += ` ${styles.inline}`;
    }
    if (disabled) {
      classValue += ` ${styles.disabled}`;
    }
    return classValue;
  }

  function handleChangeValue(val) {
    if (!isValid && val === '\n') {
      onChange('');
      return null;
    }
    onChange(val);
    autosize();
    return null;
  }

  useImperativeHandle(ref, () => ({
    getValue: () => textareaValue,
    getObject: () => ({ [name]: textareaValue }),
    getElement: () => ref.current,
    setFocus: () => {
      const textarea = textareaRef.current.querySelector('textarea');
      textarea.focus();
    },
  }));

  useEffect(() => {
    setTextareaValue(textareaWatch);
  }, [textareaWatch]);

  useEffect(() => {
    autosize();
  }, []);

  return (
    <div
      ref={textareaRef}
      className={getClassName()}
    >
      {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <div className={`${styles.container}`}>
        <textarea
          // CONTROLLER -> FIELD PROPS
          name={name}
          ref={refFromController}
          //
          value={value}
          className={disabled ? `${styles.input} ${styles.disabled}` : styles.input}
          placeholder={placeholder}
          maxLength={maxlength}
          rows="8"
          style={style}
          onChange={(e) => {
            handleChangeValue(e.target.value);
          }}
        />
        {(maxlength)
          && (
          <p className={styles['count-characters']}>
            Caractères <span>{`${value ? value.length : 0} / ${maxlength}`}</span>
          </p>
          )
        }
      </div>
    </div>
  );
});

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  maxLength: PropTypes.string,
};

export default Textarea;
