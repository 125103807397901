import {
  SPECIE_GET,
  SPECIE_LIST_GET,
  SPECIE_PUT,
  SPECIE_LOADING,
  SPECIE_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  specie: null,
  list: {},
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const specieReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  const updateSpecies = state.species || [];
  const specieIndex = (updateSpecies || []).findIndex(
    (p) => p._id === action.payload?.specie?._id,
  );
  switch (action.type) {
    case SPECIE_GET:
      updatedState = {
        ...state,
        specie: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== SPECIE_GET),
        error: null,
      };
      break;
    case SPECIE_LIST_GET:
      updatedState = {
        ...state,
        list: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== SPECIE_LIST_GET),
        error: null,
      };
      break;
    case SPECIE_PUT:
      if (typeof specieIndex === 'number') {
        updateSpecies[specieIndex] = action.payload.specie;
      }
      updatedState = {
        ...state,
        specie: action.payload.specie,
        species: updateSpecies.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
        ),
        changedAt: Date.now(),
        isLoading: state.isLoading?.filter(
          (d) => d !== `SPECIE_PUT-${action.payload.specie._id}`,
        ),
        error: null,
      };
      break;
    case SPECIE_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case SPECIE_ERROR:
      // eslint-disable-next-line no-case-declarations
      let message = action?.payload?.data?.error;
      if (message === 'That email address is already in use.') {
        message = 'Cette adresse email est déjà associée à un compte';
      } else if (message === 'Unauthorized to create an specie with this role') {
        message = 'Vous n\'êtes pas autoriser à assigner ce rôle';
      }
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default specieReducer;
