import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { formatISO, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MdAdd } from 'react-icons/md';
import {
  getObservationList,
  getObservationStatsDomains,
} from '../../actions/observations';
import { updateSpecie, getSpecie, getSpecieList } from '../../actions/species';
import { Textarea } from '../../lib/HooksFormFields';
import StyledSelect from '../../lib/HooksFormFields/StyledSelect/Select';
import styles from './home.module.scss';
import DomainStats from '../../components/DomainStats';
import ExportStatsObservations from '../../components/ExportStatsObservations';
import { getAnimals } from '../../actions/animals';

const Home = () => {
  const dispatch = useDispatch();
  const {
    authReducer,
    specieReducer,
    animalReducer,
    observationReducer,
  } = useSelector((store) => store);
  const { user } = authReducer;
  const { statsPerDomains, list } = observationReducer;
  const { list: listSpecies, specie } = specieReducer;
  const { animals } = animalReducer;
  const { control: controlSpecie, watch } = useForm();
  const { control, reset, handleSubmit } = useForm();
  const specieWatch = watch('specie');

  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const textareaRef = useRef();

  useEffect(() => {
    getSpecieList(dispatch);
    getObservationList(dispatch);
  }, []);

  // GET SPECIE
  useEffect(() => {
    if (specieWatch?.value) getSpecie(dispatch, specieWatch?.value);
    else if (!specieWatch?.value && list?.species) {
      getSpecie(dispatch, list?.species[0].value);
    }
  }, [specieWatch?.value, list?.species]);

  useEffect(() => {
    if (!specie) return;
    localStorage.setItem('specie', specie._id);
    getAnimals(dispatch, { specie: specie._id });
    getObservationStatsDomains(dispatch, { specie: specie._id });
    reset({ note: specie.note });
  }, [specie?._id]);

  const handleEditNote = useCallback(() => {
    setIsNoteOpen(true);
    console.log(textareaRef.current);
  }, [textareaRef?.current]);

  const submitNote = async (data) => {
    updateSpecie(dispatch, {
      ...data,
      _id: specie._id,
      noteAuthor: user._id,
      noteDate: formatISO(new Date()),
    });
    setIsNoteOpen(false);
  };

  const renderStats = useCallback(() => {
    // console.log(statsPerDomains, list);
    if (!statsPerDomains || Object.values(list).length === 0) return null;
    const elements = Object.entries(statsPerDomains).map(([key, value]) => {
      const listExtended = [
        { label: 'Contention', value: 'restraint' },
        { label: 'WTP', value: 'wtp' },
        { label: 'Enrichissements', value: 'enrichment' },
        { label: 'Observation comportementale', value: 'behavior' },
        ...list.domains,
      ];
      const title = listExtended.find((opt) => opt.value === key)?.label;

      return (
        <DomainStats
          key={`stats-${key}`}
          domain={key}
          stats={value}
          title={title}
        />
      );
    });
    return <div className={styles.statsTiles}>{elements}</div>;
  }, [statsPerDomains, list]);

  return (
    <div className={styles.home}>
      <div className={styles.col}>
        <h1>Bonjour {`${user.profile.firstName}`} !</h1>
        {animals.length > 0 && (
          <>
            <h3>{`${animals.length > 1 ? 'Les' : 'Le'} ${specie.name}${animals.length > 1 && 's'}`}</h3>
            <ul className={styles.animals}>
              {animals.map((animal) => (
                <li key={`animal-${animal._id}`}>
                  <div>
                    <p>{animal.name}</p>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className={styles.col}>
        <div className={styles.selectSpecies}>
          { listSpecies?.species && (
            <StyledSelect
              name="specie"
              control={controlSpecie}
              defaultValue={listSpecies?.species[0]}
              options={listSpecies?.species}
            />
          )}
        </div>
        <h4>📝 Notes</h4>
        <div className={isNoteOpen ? `${styles.containerNote} ${styles.open}` : styles.containerNote}>
          <div className={styles.note}>
            {!isNoteOpen ? (
              <div className={styles.content}>
                {specie && (
                  <>
                    <p>
                      {specie.note}
                    </p>
                    <p className={styles.author}>
                      {specie.noteAuthor.profile.firstName}
                      {` ${specie.noteAuthor.profile.lastName}`}
                      {` ${format(new Date(specie.noteDate), 'dd.MM.yy', { locale: fr })}`}
                    </p>
                  </>
                )}
              </div>
            ) : (
              <Textarea
                ref={textareaRef}
                name="note"
                className="secondary"
                control={control}
                maxlength={250}
              />
            )}
          </div>
          {isNoteOpen
            ? (
              <button
                key="submitted"
                className={`${styles.submit} secondary`}
                onClick={handleSubmit(submitNote)}
              >
                Poster
              </button>
            ) : (
              <button
                className={styles.add}
                onClick={() => handleEditNote()}
              >
                <span className="icon"><MdAdd size={35} /></span>
              </button>
            )
          }
        </div>
        <div className={styles.header}>
          <h4>📊 Data </h4>
          <ExportStatsObservations />
        </div>
        {renderStats()}
      </div>
    </div>
  );
};

export default Home;
