import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import specieReducer from './specieReducer';
import animalReducer from './animalReducer';
import observationReducer from './observationReducer';

const rootReducer = combineReducers({
  authReducer,
  specieReducer,
  observationReducer,
  animalReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
