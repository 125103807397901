import React, {
  useState, useEffect, useMemo,
} from 'react';
import {
  format,
  startOfDay,
  eachDayOfInterval,
  parse,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import colors from '../../constants';
import { evalCondition } from '../../utils';
import LineChart from '../LineChart';
// import FoodChart from '../FoodChart';
import conditions from './conditions.json';
import styles from './dashboard-nutrition.module.scss';

const DashboardNutrition = ({
  animals = [],
  observations = [],
  range = {},
}) => {
  const [animalsData, setAnimalsData] = useState();

  const filteredObservations = useMemo(() => (
    observations.filter((observation) => observation.domain === 'nutrition')
  ), [observations]);

  useEffect(() => {
    if (filteredObservations?.length === 0 || animals.length === 0) {
      setAnimalsData();
      return;
    }
    const nutritionData = {};

    animals.forEach((animal) => {
      nutritionData[animal._id] = {
        name: animal.name,
        weight: {},
        foods: {},
        NECEcho: {},
        bodyCondition: {},
        diversityFoods: {},
      };
    });
    filteredObservations.forEach((observation) => {
      const date = format(new Date(observation.observationDate), 'dd-MM-yyyy', { locale: fr });

      observation.animals.forEach((animal) => {
        const item = nutritionData[animal];

        // HANDLE DIVERSITY FOODS
        if (observation.diversityFoods.length > 0 && (!item.diversityFoods[date]
          || item.diversityFoods[date].observationDate < observation.observationDate)) {
          const currentDiversityFoods = item.diversityFoods[date] || {
            date,
            labelValue: '',
            value: 0,
            observationDate: observation.observationDate,
          };

          const uniqDiversityFoods = Array.from(new Set([
            ...currentDiversityFoods.labelValue.split(' / '),
            ...observation.diversityFoods,
          ])).filter((d) => d !== '');

          currentDiversityFoods.labelValue = uniqDiversityFoods.join(' / ');
          currentDiversityFoods.value = uniqDiversityFoods.length;

          item.diversityFoods[date] = currentDiversityFoods;
        }
        nutritionData[animal] = item;
      });

      observation.nutritions.forEach(({
        animal,
        weight,
        NECEcho,
        bodyCondition,
        foods,
      }) => {
        const item = nutritionData[animal._id] || {
          weight: {},
          foods: {},
          NECEcho: {},
          bodyCondition: {},
          diversityFoods: {},
        };

        item.name = animal.name;

        // HANDLE SET 'weight' WITH THE MOST RECENT VALUE OF THE DATE
        if ((typeof weight === 'number') && (!item.weight[date]
          || item.weight[date].observationDate < observation.observationDate)
        ) {
          item.weight[date] = {
            date,
            value: weight,
            observationDate: observation.observationDate,
            name: 'Poids',
          };
        }

        // HANDLE SET 'NECEcho' WITH THE MOST RECENT VALUE OF THE DATE
        if ((typeof NECEcho === 'number') && (!item.NECEcho[date]
          || item.NECEcho[date].observationDate < observation.observationDate)
        ) {
          item.NECEcho[date] = {
            date,
            value: NECEcho,
            observationDate: observation.observationDate,
            name: 'NEC Echo',
          };
        }

        // HANDLE SET 'bodyCondition' WITH THE MOST RECENT VALUE OF THE DATE
        if ((typeof bodyCondition === 'number') && (!item.bodyCondition[date]
          || item.bodyCondition[date].observationDate < observation.observationDate)
        ) {
          item.bodyCondition[date] = {
            date,
            value: bodyCondition,
            observationDate: observation.observationDate,
            name: 'BCS',
          };
        }

        const foodsValues = item?.foods?.[date]?.details || [];
        item.foods[date] = {
          date,
          details: [...foodsValues, ...foods],
          observationDate: observation.observationDate,
          name: 'Appétit',
        };

        nutritionData[animal._id] = item;
      });
    });

    // * HANDLE DIVERSITY FOODS
    // * ADD DATE OF THE PREVIOUS OBSERVATION
    // * IF A DAY HAVE NO OBSERVATION
    const start = startOfDay(range.start);
    const end = startOfDay(range.end);
    let dates = eachDayOfInterval({ start, end });
    dates = dates.map((d) => format(d, 'dd-MM-yyyy', { locale: fr }));

    Object.entries(nutritionData).forEach(([animal, animalData]) => {
      dates.forEach((date) => {
        const observation = animalData.diversityFoods[date];
        if (observation) {
          nutritionData[animal].diversityFoods[date] = {
            ...observation,
            date,
          };
        }
      });
    });

    Object.entries(nutritionData).forEach(([animal, animalData]) => {
      Object.entries(animalData).forEach(([key, data]) => {
        if (key !== 'name') {
          nutritionData[animal][key] = [];

          const values = Object.values(data);
          // const haveDates = values.every((value) => 'date' in value);
          // console.log('haveDates', haveDates);
          values
            .sort((a, b) => parse(a.date, 'dd-MM-yyyy', new Date()) - parse(b.date, 'dd-MM-yyyy', new Date()))
            .forEach((d) => {
              const object = { ...d };
              let color = colors.good;

              if (key === 'foods') {
                object.details = object.details.reduce((arr, k) => {
                  const currentArray = arr;
                  const index = arr.findIndex((j) => j.type === k.type);
                  if (typeof index === 'number' && index > -1) {
                    currentArray[index] = {
                      ...arr[index],
                      given: (currentArray[index].given || 0) + (k.given || 0),
                      tookOf: (currentArray[index].tookOf || 0) + (k.tookOf || 0),
                    };
                  } else {
                    currentArray.push({
                      ...k,
                      given: (k.given || 0),
                      tookOf: (k.tookOf || 0),
                    });
                  }
                  return currentArray;
                }, []);

                const totalGiven = object.details.reduce((acc, t) => acc + t.given, 0);
                const totalTookOf = object.details
                  .reduce((acc, t) => acc + (t.tookOf > t.given ? t.given : t.tookOf), 0);

                object.details = object.details.map((k) => {
                  const tookOf = k.tookOf > k.given ? k.given : k.tookOf;
                  let percentTookOf = Math.round((tookOf * 100) / k.given);
                  if (percentTookOf < 0 || Number.isNaN(percentTookOf)) percentTookOf = 0;
                  const percentGivenOnTotal = (k.given * 100) / totalGiven;

                  let percent = (percentGivenOnTotal / 100) * percentTookOf;

                  if (percent < 0 || Number.isNaN(percent)) percent = 0;
                  return ({
                    ...k,
                    percent,
                    percentTookOf,
                    percentAte: 100 - percentTookOf,
                    percentGivenOnTotal,
                    tookOf,
                    infoValue: `${k.given - tookOf} mangées sur ${k.given}`,
                  });
                });
                object.totalGiven = totalGiven;
                object.totalTookOf = totalTookOf;
                object.value = Math.round(((totalGiven - totalTookOf) * 100) / totalGiven);
                if (Number.isNaN(object.value)) object.value = 0;
              }

              if (conditions[key]) {
                const result = evalCondition(conditions[key], object.value);
                color = colors?.[result] || colors.bad;
              }

              if ((Array.isArray(object.value) && object.value.length > 0)
              || !Array.isArray(object.value)) {
                nutritionData[animal][key].push({
                  ...object,
                  color,
                });
              }
            });
        }
      });
    });

    setAnimalsData(nutritionData);
  }, [filteredObservations]);

  const renderCharts = (data) => (
    <div className={styles.containerCharts}>
      <div className={styles.chart}>
        <h3>Appétit</h3>
        <LineChart data={data.foods} domainY={[0, 100]} unit="%" />
        {/* <FoodChart data={data.foods} /> */}
      </div>
      <div className={styles.chart}>
        <h3>Diversité alimentaire</h3>
        <LineChart data={data.diversityFoods} />
      </div>
      <div className={styles.chart}>
        <h3>Poids</h3>
        <LineChart data={data.weight} unit="kg" />
      </div>
      <div className={styles.chart}>
        <h3>BCS</h3>
        <LineChart domainY={[0, 4]} data={data.bodyCondition} />
      </div>
      <div className={styles.chart}>
        <h3>NEC Echo</h3>
        <LineChart data={data.NECEcho} unit="mm" />
      </div>
    </div>
  );

  return (
    <>
      {animalsData && (
      <>
        {Object.entries(animalsData).sort(([, a], [, b]) => a.name.localeCompare(b.name))
          .map(([animal, data]) => (
            <React.Fragment key={`bowl-${animal}`}>
              <h3 className={styles.animalName}>{data.name}</h3>
              <div className={styles.animal}>
                {renderCharts(data)}
              </div>
            </React.Fragment>
          ))}
      </>
      )}
    </>
  );
};

export default DashboardNutrition;
