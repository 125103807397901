import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetError } from '../../../../actions/auth';

import {
  InputPassword,
  ErrorField,
} from '../../../../lib/HooksFormFields';

import styles from './resetpwd-form.module.scss';

const ResetPwdForm = ({
  errorMessage,
  signinLink,
  succeedMessage,
  submit,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const watchPassword = watch('password');
  const watchPasswordConfirm = watch('password-confirm');

  useEffect(() => resetError(dispatch), []);

  useEffect(() => {
    if (errorMessage) resetError(dispatch);
  }, [watchPassword, watchPasswordConfirm]);

  function onSubmit(data) {
    submit(data);
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['resetpwd-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Nouveau mot de passe"
            className="login"
          />
          {errors.password && <ErrorField message={errors.password.message} />}
        </div>
        <div className={styles['container-field']}>
          <InputPassword
            name="confirm-password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label="Confirmation du mot de passe"
            className="login"
          />
          {errors['confirm-password'] && <ErrorField message={errors['confirm-password'].message} />}
        </div>
        {(signinLink && succeedMessage) ? (
          <div className={styles.succeed}>
            <NavLink to={signinLink}>
              <button
                className={`secondary ${styles.btnSucceed}`}
              >
                Se connecter à mon compte
              </button>
            </NavLink>
            <p className={styles['message-succeed']}>{succeedMessage}</p>
          </div>
        ) : (
          <div className={styles.submit}>
            <button onClick={handleSubmit(onSubmit)}>Valider</button>
            {(!errors.email && !errors.password) && errorMessage
              && (
              <p className={styles['message-error']}>
                {errorMessage}
              </p>
              )}
          </div>
        )}
      </div>

    </div>
  );
};

ResetPwdForm.propTypes = {
  submit: PropTypes.func.isRequired,
  signinLink: PropTypes.string,
  errorMessage: PropTypes.string,
  succeedMessage: PropTypes.string,
};

export default ResetPwdForm;
