import React, {
  useState, useId, useLayoutEffect,
} from 'react';
import {
  LineChart as RLineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Dot,
} from 'recharts';
import styles from './line-chart.module.scss';

const LineChart = ({
  data, domainY = null, unit = null, allowDecimals = false, axisYNotUnit = false,
}) => {
  const pieID = useId();
  const [widthYAxis, setWidthYAxis] = useState();

  useLayoutEffect(() => {
    const axisY = document.getElementById(pieID)?.querySelector('.yAxis');
    if (axisY) {
      setWidthYAxis(Math.ceil(axisY.getBoundingClientRect().width));
    }
  }, []);

  const renderTooltip = (props) => {
    const { payload, label } = props;
    if (payload && payload.length) {
      const { name, value, labelValue } = payload[0].payload;
      return (
        <div className={styles.customTooltip}>
          <p>{label}</p>
          <p>{name ? <>{name} : </> : ''}{labelValue || `${value}${unit || ''}`}</p>
        </div>
      );
    }
    return null;
  };

  const formatXAxis = (label) => (
    `${label.replace(/-\d{4,4}$/, '')}`
  );
  return (
    <div id={pieID}>
      <RLineChart
        width={800}
        height={400}
        data={data}
        margin={{
          top: 5, right: 0, left: 0, bottom: 5,
        }}
      >
        <XAxis
          dataKey="date"
          height={60}
          angle={-45}
          textAnchor="end"
          tickFormatter={formatXAxis}
        />
        <YAxis
          domain={domainY}
          unit={!axisYNotUnit ? unit : null}
          width={widthYAxis || 40}
          allowDecimals={allowDecimals}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          isAnimationActive={false}
          content={renderTooltip}
          active
        />
        <Line
          type="monotone"
          isAnimationActive={false}
          dataKey="value"
          stroke="#7d8189"
          connectNulls
          // activeDot={{ r: 8 }}
          activeDot={<CustomizedDot active />}
          dot={<CustomizedDot />}
          // dot={{
          //   stroke: '#74BE73', strokeWidth: 1, r: 4, strokeDasharray: '',
          // }}
        />
      </RLineChart>
    </div>
  );
};

const CustomizedDot = ({
  cx,
  cy,
  active,
  payload,
}) => (
  <Dot
    cx={cx}
    cy={cy}
    r={active ? 6 : 4}
    stroke={payload.color || '#74BE73'}
    fill={payload.color || '#FFFFFF'}
  />
);

// {/* <svg
// x={cx - 10}
// y={cy - 10}
// width={20}
// height={20}
// stroke={payload.color || '#74BE73'}
// fill={payload.color || '#FFFFFF'}
// viewBox="0 0 20 20"
// >
// <circle
//   cx={10}
//   cy={10}
//   r="4"
//   stroke={payload.color || '#74BE73'}
//   fill={payload.color || '#FFFFFF'}
// />
// </svg */}

export default LineChart;
