import {
  getData,
} from './index';

import {
  OBSERVATIONS_GET,
  OBSERVATION_STATS_SPECIE_GET,
  OBSERVATION_STATS_SPECIE_DOMAINS_GET,
  OBSERVATION_LIST_GET,
  OBSERVATION_LOADING,
  OBSERVATION_ERROR,
} from './types';

const getObservations = async (dispatch, animals, params) => {
  const { specie, ...query } = params;
  const queryString = new URLSearchParams(query).toString();
  const url = `/observations${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: OBSERVATION_LOADING,
    payload: OBSERVATIONS_GET,
  });
  const response = await getData(OBSERVATION_ERROR, url, dispatch, true);
  const observations = response.data?.observations;

  if (observations) {
    dispatch({
      type: OBSERVATIONS_GET,
      payload: observations,
    });
  }
};

const getObservationList = async (dispatch) => {
  const url = '/observations/list';
  dispatch({
    type: OBSERVATION_LOADING,
    payload: OBSERVATION_LIST_GET,
  });
  const response = await getData(OBSERVATION_ERROR, url, dispatch, true);
  if (response.data?.list) {
    dispatch({
      type: OBSERVATION_LIST_GET,
      payload: response.data?.list,
    });
  }
};

const getObservationStatsDomains = async (dispatch, params) => {
  const { specie, ...query } = params;
  const queryString = new URLSearchParams(query).toString();
  const url = `/observations/stats/${specie}${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: OBSERVATION_LOADING,
    payload: OBSERVATION_STATS_SPECIE_DOMAINS_GET,
  });
  const response = await getData(OBSERVATION_ERROR, url, dispatch, true);
  if (response.data?.stats) {
    dispatch({
      type: OBSERVATION_STATS_SPECIE_DOMAINS_GET,
      payload: response.data?.stats,
    });
  }
};

const getObservationStats = async (dispatch, params) => {
  const { specie, ...query } = params;
  const queryString = new URLSearchParams(query).toString();
  const url = `/observations/stats/date/${specie}${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: OBSERVATION_LOADING,
    payload: OBSERVATION_STATS_SPECIE_GET,
  });
  const response = await getData(OBSERVATION_ERROR, url, dispatch, true);
  if (response.data?.stats) {
    dispatch({
      type: OBSERVATION_STATS_SPECIE_GET,
      payload: response.data?.stats,
    });
  }
};

export {
  getObservations,
  getObservationList,
  getObservationStats,
  getObservationStatsDomains,
};
