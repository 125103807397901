const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj,
  );
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

const set = (obj, path, value) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
};

const evalCondition = (conds, value) => {
  let result = null;
  Object.entries(conds).forEach(([key, cond]) => {
    const str = cond.replace(/value/g, value);
    // eslint-disable-next-line no-eval
    if (!result && eval(str)) result = key;
  });
  return result;
};

const numAverage = (array) => {
  const b = array.length;
  let c = 0; let
    i;
  for (i = 0; i < b; i += 1) {
    c += Number(array[i]);
  }
  // round to 1 decimal places
  return Math.round((c / b) * 10) / 10;
};

// eslint-disable-next-line consistent-return
const downloadDolpinsStatsXLSX = async (params) => {
  const config = {
    method: 'GET',
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
    },
  };

  const queryString = new URLSearchParams(params).toString();
  const url = `${process.env.REACT_APP_API_URL}/observations/export/dolphins${queryString ? `?${queryString}` : ''}`;

  try {
    const response = await fetch(url, config);
    if (response.status !== 200) {
      throw new Error();
    }
    const blob = await response.blob();
    const urlObject = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', `export-observations-stats.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    console.log(e);
    return null;
  }
};

const getBehaviorCharactsState = async () => {
  const config = {
    method: 'GET',
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
    },
  };
  const url = `${process.env.REACT_APP_API_URL}/behavior-characts/stage`;
  try {
    const response = await fetch(url, config);
    const { stage } = (await response.json());
    return stage;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export {
  get,
  set,
  numAverage,
  evalCondition,
  downloadDolpinsStatsXLSX,
  getBehaviorCharactsState,
};
