/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MdLogout } from 'react-icons/md';
import { logout } from '../../actions/auth';
import logo from '../../assets/images/logo2.svg';
import styles from './sidebar.module.scss';

const Sidebar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store).authReducer;

  const renderProfilePicture = () => {
    const token = localStorage.getItem('token').replace('JWT ', '');
    const url = `${process.env.REACT_APP_API_URL}/files/public/${user?.picture?.path}?token=${token}`;
    return (
      <div
        className={styles.profilePicture}
        style={user?.picture?.path ? { backgroundImage: `url(${url})` } : {}}
      >
        {!user?.picture?.path && (
          <p>
            {user.profile.firstName[0]}
            {user.profile.lastName[0]}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <img src={logo} alt="Sawa" />
      </div>
      <div className={styles.navigation}>
        <ul>
          <li>
            <NavLink to="home" className={({ isActive }) => isActive ? styles.active : ''}>Accueil</NavLink>
          </li>
          <li>
            <NavLink to="data?domain=nutrition" className={({ isActive }) => isActive ? styles.active : ''}>Data</NavLink>
          </li>
          <li>
            <NavLink to="admin" className={styles.disabled}>Administration</NavLink>
          </li>
          <li>
            <NavLink to="historic" className={styles.disabled}>Historique</NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.bottom}>
        {renderProfilePicture()}
        <div className={styles.logout}>
          <button onClick={() => logout(dispatch)}><MdLogout size={24} /></button>
          <p onClick={() => logout(dispatch)}>
            <span>Déconnexion</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
