import React, {
  useState, useEffect, useMemo,
} from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import colors from '../../constants';
import BarChart from '../BarChart';
import styles from './dashboard-health.module.scss';

const DashboardHealth = ({
  observations = [],
  list,
}) => {
  const [animalsData, setAnimalsData] = useState();

  const filteredObservations = useMemo(() => (
    observations.filter((observation) => observation.domain === 'health')
  ), [observations]);

  useEffect(() => {
    if (filteredObservations?.length === 0) {
      setAnimalsData();
      return;
    }

    const getColorAndValue = (strValue) => {
      let value = 0.5;
      let color = colors.warn;
      if (strValue === 'good') {
        value = 0.5;
        color = colors.good;
      }
      if (strValue === 'bad') {
        value = 1;
        color = colors.bad;
      }
      if (strValue === null) value = 0;
      return { value, color };
    };

    const healthData = {};
    filteredObservations.forEach((observation) => {
      const date = format(new Date(observation.observationDate), 'dd-MM-yyyy', { locale: fr });
      observation.records.forEach(({
        animal,
        bloodAnalysis,
        blowholeAnalysis,
      }) => {
        const item = healthData[animal._id] || {
          name: animal.name,
          bloodAnalysis: {},
          blowholeAnalysis: {},
        };

        // HANDLE SET 'bloodAnalysis' WITH THE MOST RECENT VALUE OF THE DATE
        if (!item.bloodAnalysis[date]
          || item.bloodAnalysis[date].observationDate < observation.observationDate
        ) {
          const labelValue = list.healthAnalisysStates.find((opt) => opt.value === bloodAnalysis)?.label || '---';
          item.bloodAnalysis[date] = {
            date,
            labelValue,
            value: getColorAndValue(bloodAnalysis).value,
            color: getColorAndValue(bloodAnalysis).color,
            observationDate: observation.observationDate,
          };
        }

        // HANDLE SET 'blowholeAnalysis' WITH THE MOST RECENT VALUE OF THE DATE
        if (blowholeAnalysis && (!item.blowholeAnalysis[date]
          || item.blowholeAnalysis[date].observationDate < observation.observationDate)
        ) {
          const labelValue = list.healthAnalisysStates.find((opt) => opt.value === blowholeAnalysis)?.label || '---';
          item.blowholeAnalysis[date] = {
            date,
            labelValue,
            value: getColorAndValue(blowholeAnalysis).value,
            color: getColorAndValue(blowholeAnalysis).color,
            observationDate: observation.observationDate,
          };
        }

        healthData[animal._id] = item;
      });
    });

    // COMPUTE BOWL DATA FOR CHARTS
    Object.entries(healthData).forEach(([animal, animalData]) => {
      Object.entries(animalData).forEach(([key, data]) => {
        if (key !== 'name') healthData[animal][key] = Object.values(data);
      });
    });

    setAnimalsData(healthData);
  }, [filteredObservations]);

  const renderCharts = (data) => (
    <div className={styles.containerCharts}>
      <div className={styles.chart}>
        <h3>Analyses sang</h3>
        <BarChart hideYAxis domain={[0, 1]} data={data.bloodAnalysis} />
      </div>
      <div className={styles.chart}>
        <h3>Analyses de l&apos;évent</h3>
        {data.blowholeAnalysis.length === 0
          ? <p>Aucune donnée</p>
          : <BarChart hideYAxis domain={[0, 1]} data={data.blowholeAnalysis} />
        }
        {console.log(data.blowholeAnalysis)}
      </div>
    </div>
  );

  return (
    <>
      {animalsData && (
      <>
        {Object.entries(animalsData).sort(([, a], [, b]) => a.name.localeCompare(b.name))
          .map(([animal, data]) => (
            <React.Fragment key={`bowl-${animal}`}>
              <h3 className={styles.animalName}>{data.name}</h3>
              <div className={styles.animal}>
                {renderCharts(data)}
              </div>
            </React.Fragment>

          ))}
      </>
      )}
    </>
  );
};

export default DashboardHealth;
