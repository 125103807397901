import React from 'react';
import {
  Radar, RadarChart as RRadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip,
} from 'recharts';
import styles from './radar-chart.module.scss';

const RadarChart = ({ data, unit = '' }) => {
  const renderTooltip = (props) => {
    const { payload, label } = props;
    if (payload && payload.length) {
      const { value } = payload[0].payload;
      return (
        <div className={styles.customTooltip}>
          <p><span>{label} :</span>{` ${value}${unit}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <RRadarChart width={500} height={300} cx="50%" cy="50%" outerRadius="80%" data={data}>
      <PolarGrid />
      <Tooltip content={renderTooltip} />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis
        domain={[0, 100]}
        tickFormatter={(value) => Number.isInteger(value) ? value : ''}
      />
      <Radar name="Mike" dataKey="value" stroke="#3D90B1" fill="#3D90B1" fillOpacity={0.6} />
    </RRadarChart>
  );
};

export default RadarChart;
