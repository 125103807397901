import React, {
  useMemo, useId, useState, useLayoutEffect,
} from 'react';
import {
  Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
// import { get } from '../../utils';
import styles from './diversity-behavior-chart.module.scss';

const CustomLegend = ({ colors }) => (
  <ul className={styles.customLegend}>
    {Object.entries(colors)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).map(([key, color]) => (
        <li key={`legend-${key}`} className={styles.legendItem}>
          <span
            className={styles.legendColor}
            style={{ backgroundColor: color || '#87CEEB' }}
          />
          <span className={styles.legendLabel}>{key}</span>
        </li>
      ))}
  </ul>
);
const getMaxStackLength = (data) => {
  let maxStackLength = 0;
  data.forEach((item) => {
    const stackLength = item.value.length;
    if (stackLength > maxStackLength) {
      maxStackLength = stackLength;
    }
  });
  return maxStackLength;
};

const DiversityBehaviorChart = ({ data }) => {
  const colors = {};
  const chartID = useId();
  const [widthYAxis, setWidthYAxis] = useState();

  useLayoutEffect(() => {
    const axisY = document.getElementById(chartID)?.querySelector('.yAxis');
    if (axisY) {
      setWidthYAxis(Math.ceil(axisY.getBoundingClientRect().width));
    }
  }, []);

  const renderTooltip = (props) => {
    const { payload, label } = props;
    if (payload && payload.length) {
      const sorted = payload[0].payload.value.sort((a, b) => a.name.localeCompare(b.name));
      return (
        <div className={styles.customTooltip}>
          <p>{label}</p>
          {sorted.length > 0 && (
            <ul>
              {sorted.map((d) => (
                <li key={d.name}>
                  <p>{d.name}: {d.count}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }
    return null;
  };

  const formatXAxis = (label) => (
    `${label.replace(/-\d{4,4}$/, '')}`
  );

  let barKeys = new Set();
  data.forEach((d) => d.value.forEach((v) => {
    barKeys.add(v.name);
    colors[v.name] = v.color;
  }));
  barKeys = Array.from(barKeys);

  const maxStackLength = getMaxStackLength(data);

  const sortedData = useMemo(() => {
    const arr = [];
    data.forEach((d) => {
      arr.push({
        ...d,
        value: d.value.sort((a, b) => b.name.localeCompare(a.name)),
      });
    });
    return arr;
  }, [data]);

  return (
    <div id={chartID}>
      <BarChart
        width={600}
        height={400}
        data={sortedData}
        barGap={0}
        categoryGap={0}
        margin={{
          top: 20, right: 30, left: 0, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          height={60}
          angle={-45}
          textAnchor="end"
          tickFormatter={formatXAxis}
        />
        <YAxis width={widthYAxis || 40} />
        <Legend content={<CustomLegend colors={colors} />} />
        <Tooltip isAnimationActive={false} content={renderTooltip} />
        {Array.from({ length: maxStackLength }).map((_, index) => (
          <Bar
            key={index}
            barSize={60}
            dataKey={`value[${index}].count`}
            stackId="a"
          >
            {data.map((entry, i) => (
              <Cell key={i} fill={entry.value[index]?.color} />
            ))}
          </Bar>
        ))}
      </BarChart>
    </div>
  );
};

export default DiversityBehaviorChart;
