import {
  ANIMALS_GET,
  ANIMAL_GET,
  ANIMAL_LOADING,
  ANIMAL_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  animal: null,
  animals: [],
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const animalReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case ANIMALS_GET:
      updatedState = {
        ...state,
        animals: action.payload.animals,
        isLoading: state.isLoading?.filter((d) => d !== ANIMALS_GET),
        error: null,
      };
      break;
    case ANIMAL_GET:
      updatedState = {
        ...state,
        animal: action.payload.animal,
        isLoading: state.isLoading?.filter((d) => d !== ANIMAL_GET),
        error: null,
      };
      break;
    case ANIMAL_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case ANIMAL_ERROR:
      // eslint-disable-next-line no-case-declarations
      const message = action?.payload?.data?.error || action?.payload?.message;
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default animalReducer;
