import axios from 'axios';
import {
  getData,
  errorHandler,
} from './index';

import {
  ANIMALS_GET,
  ANIMAL_GET,
  ANIMAL_LOADING,
  ANIMAL_ERROR,
} from './types';

const getAnimalWithStats = async (dispatch, id, queryDate) => {
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  const responseAnimal = await axios.get(`${process.env.REACT_APP_API_URL}/animals/${id}`, config);
  const animal = responseAnimal?.data?.animal;

  const queryStringDate = new URLSearchParams(queryDate).toString();
  const responseAnimalStats = await axios.get(`${process.env.REACT_APP_API_URL}/animals/stats/${id}${queryStringDate ? `?${queryStringDate}` : ''}`, config);
  const animalStats = responseAnimalStats?.data?.stats;

  const responseAnimalStatsPerDay = await axios.get(`${process.env.REACT_APP_API_URL}/animals/stats/date/${id}${queryStringDate ? `?${queryStringDate}` : ''}`, config);
  const animalStatsPerDay = responseAnimalStatsPerDay?.data?.stats;

  if (animal && animalStats && animalStatsPerDay) {
    dispatch({
      type: ANIMAL_GET,
      payload: {
        animal: {
          ...animal,
          stats: animalStats,
          statsPerDay: animalStatsPerDay,
        },
      },
    });
  } else {
    errorHandler(dispatch, {
      message: 'Error with get animal with stats',
    }, ANIMAL_ERROR);
  }
};

const getAnimals = async (dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/animals${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: ANIMAL_LOADING,
    payload: ANIMALS_GET,
  });
  const response = await getData(ANIMAL_ERROR, url, dispatch, true);
  if (response.data?.animals) {
    dispatch({
      type: ANIMALS_GET,
      payload: response.data,
    });
  }
};

export {
  getAnimals,
  getAnimalWithStats,
};
