import React, { useMemo } from 'react';
import { formatISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import { downloadDolpinsStatsXLSX } from '../../utils';
import { DatePicker } from '../../lib/HooksFormFields';
import styles from './exports-stats-observations.module.scss';

const ExportStatsObservations = () => {
  const {
    control,
    handleSubmit,
    watch,
  } = useForm();
  const watchStart = watch('start');
  const watchEnd = watch('end');
  const handleDownload = (data) => {
    const params = {
      start: formatISO(data.start),
      end: formatISO(data.end),
    };
    downloadDolpinsStatsXLSX(params);
  };

  const isDisabled = useMemo(() => {
    if (!watchStart || !watchEnd) return true;
    return watchStart.getTime() > watchEnd.getTime();
  }, [watchStart, watchEnd]);

  return (
    <div className={styles.container}>
      <DatePicker
        name="start"
        control={control}
        placeholder="Date de début"
        rules={{
          required: true,
        }}
      />
      <DatePicker
        name="end"
        control={control}
        placeholder="Date de fin"
        rules={{
          required: true,
        }}
      />
      <button className={isDisabled ? 'disabled' : ''} onClick={handleSubmit(handleDownload)}>Exporter</button>
    </div>
  );
};

export default ExportStatsObservations;
