import React, {
  useState, useEffect, useMemo, Fragment,
} from 'react';
import { format, eachDayOfInterval } from 'date-fns';
import { fr } from 'date-fns/locale';
import LineChart from '../LineChart';
import BarChart from '../BarChart';
import RadarChart from '../RadarChart';
import DiversityBehaviorChart from '../DiversityBehaviorChart';
import colors from '../../constants';
import conditions from './conditions.json';
import styles from './dashboard-behavior.module.scss';
import { getBehaviorCharactsState, evalCondition, numAverage } from '../../utils';

const charactsProximity = process.env.REACT_APP_CHARACTS_PROXIMITY.split(',');
const DashboardBehavior = ({
  observations = [],
  animals = [],
  dateRange = {},
  list = {},
}) => {
  const [animalsData, setAnimalsData] = useState();
  const [animalsRadarData, setAnimalsRadarData] = useState();
  const [behaviorDiversity, setBehaviorDiversity] = useState();

  const filteredObservations = useMemo(() => (
    observations.filter((observation) => ['restraint', 'wtp', 'behavior', 'enrichment'].includes(observation.domain))
  ), [observations]);

  useEffect(() => {
    const getData = async () => {
      const observationsBehaviorIndividual = filteredObservations.filter((observation) => observation.domain === 'behavior' && observation.type === 'individual');
      const firstStage = await getBehaviorCharactsState();

      const data = firstStage.sort((a, b) => a.name.localeCompare(b.name)).map((d) => ({
        _id: d._id,
        subject: d.name,
        value: 0,
        count: 0,
        fullMark: 150,
      }));

      const behaviorRadarData = {};
      animals.forEach((animal) => {
        behaviorRadarData[animal._id] = {
          name: animal.name,
          behavior: data.map((d) => ({ ...d })),
        };
      });

      observationsBehaviorIndividual.forEach(({ animals: obsAnimals, behaviorCharacts }) => {
        const animal = obsAnimals[0];
        behaviorCharacts.forEach((characts) => {
          const topLevelCharact = characts[0];
          const behaviorIndex = behaviorRadarData[animal._id].behavior
            .findIndex((b) => b._id === topLevelCharact._id);
          if (behaviorIndex >= 0 && behaviorRadarData[animal._id].behavior[behaviorIndex]) {
            behaviorRadarData[animal._id].behavior[behaviorIndex] = {
              ...behaviorRadarData[animal._id].behavior[behaviorIndex],
              count: behaviorRadarData[animal._id].behavior[behaviorIndex].count + 1,
            };
          }
        });
      });

      Object.values(behaviorRadarData).forEach((d) => {
        const totalCount = d.behavior.reduce((acc, b) => acc + b.count, 0);
        d.behavior.forEach((item) => {
          let percent = Math.round((item.count * 100) / totalCount);
          if (Number.isNaN(percent)) percent = 0;
          // eslint-disable-next-line no-param-reassign
          item.value = percent;
        });
      });

      setAnimalsRadarData(behaviorRadarData);
    };
    getData();
  }, [filteredObservations, animals]);

  useEffect(() => {
    if (filteredObservations?.length === 0
      || !dateRange.start
      || !dateRange.end
      || animals.length === 0
    ) {
      setAnimalsData();
      return;
    }

    const observationsRestraint = filteredObservations.filter((observation) => observation.domain === 'restraint');
    const observationsWtp = filteredObservations.filter((observation) => observation.domain === 'wtp');
    const observationsEnrichment = filteredObservations.filter((observation) => observation.domain === 'enrichment');
    const observationsBehavior = filteredObservations.filter((observation) => observation.domain === 'behavior');

    const behaviorData = {};

    const defaultItem = {
      name: null,
    };

    const proximityDefault = {
      Personne: { name: 'Personne', value: 0, color: colors.good },
    };
    animals.forEach((animal) => {
      proximityDefault[animal._id] = { name: animal.name, value: 0, color: colors.good };
    });

    animals.forEach((animal) => {
      const proximity = JSON.parse(JSON.stringify(proximityDefault));
      delete proximity[animal._id];
      behaviorData[animal._id] = {
        ...defaultItem,
        name: animal.name,
        wtp: {},
        restraint: {},
        enrichment: {},
        behaviorDiversity: {},
        proximity,
      };
    });

    const behaviorDiversityData = {};
    // BEHAVIOR
    observationsBehavior.forEach((observation) => {
      const date = format(new Date(observation.observationDate), 'dd-MM-yyyy', { locale: fr });

      // behaviorDiversity
      if (observation.type === 'group') {
        const itemBehaviorDiversity = behaviorDiversityData[date] || {
          date,
          value: [],
          observationDate: observation.observationDate,
        };

        itemBehaviorDiversity.value = [
          ...itemBehaviorDiversity.value,
          ...observation.behaviorCharacts.map((characts) => ({
            _id: characts[0]._id,
            name: characts[0].name,
            color: characts[0]?.color || '#87CEEB',
          })),
        ];
        behaviorDiversityData[date] = itemBehaviorDiversity;
      }

      observation.animals.forEach((animal) => {
        const animalId = animal._id;
        const item = behaviorData[animalId];

        if (observation.type === 'group') {
          const itemBehaviorDiversity = item.behaviorDiversity?.[date] || {
            date,
            value: [],
            observationDate: observation.observationDate,
          };

          itemBehaviorDiversity.value = [
            ...itemBehaviorDiversity.value,
            ...observation.behaviorCharacts.map((characts) => ({
              _id: characts[0]._id,
              name: characts[0].name,
              color: characts[0]?.color || '#87CEEB',
            })),
          ];
          item.behaviorDiversity[date] = itemBehaviorDiversity;
        }

        if (observation.type === 'individual') {
          // PROXIMITY
          observation.behaviorCharacts.forEach((characts) => {
            const findIndex = characts
              .findIndex((charact) => charactsProximity.includes(charact._id));
            if (findIndex >= 0 && characts[findIndex + 1]) {
              if (characts[findIndex + 1].animal && characts[findIndex + 1].animal !== animalId) {
                item.proximity[characts[findIndex + 1].animal].value += 1;
              } else if (characts[findIndex + 1].animal !== animalId) {
                item.proximity.Personne.value += 1;
              }
            }
          });
        }

        behaviorData[animalId] = item;
      });
    });

    // WTP
    observationsWtp.forEach((observation) => {
      const date = format(new Date(observation.observationDate), 'dd-MM-yyyy', { locale: fr });

      observation.notes.forEach((animalNote) => {
        const animalId = animalNote.animal._id;
        const item = behaviorData[animalId];
        if ((typeof animalNote.note === 'number')) {
          let notes = item.wtp[date]?.notes || [];
          notes = [...notes, animalNote.note];
          const average = numAverage(notes);
          const result = evalCondition(conditions.wtp, average);
          item.wtp[date] = {
            date,
            name: 'Note',
            notes,
            value: average,
            color: colors?.[result] || colors.bad,
            observationDate: observation.observationDate,
          };
        }
        behaviorData[animalId] = item;
      });
    });

    // ENRICHMENT
    observationsEnrichment.forEach((observation) => {
      const date = format(new Date(observation.observationDate), 'dd-MM-yyyy', { locale: fr });
      const labelCategory = list?.categoriesEnrichment.find((opt) => opt.value === observation.category)?.label || '---';
      const labelComment = list?.detailsEnrichment.find((opt) => opt.value === observation.comment)?.label || '---';
      observation.notes.forEach((animalNote) => {
        const animalId = animalNote.animal._id;
        const item = behaviorData[animalId];
        if (typeof animalNote.note === 'number') {
          let notes = item.enrichment[date]?.notes || [];
          notes = [...notes, animalNote.note];
          const average = numAverage(notes);
          const result = evalCondition(conditions.enrichment, average);
          item.enrichment[date] = {
            date,
            labelValue: `${labelCategory} - ${labelComment}: ${average}`,
            notes,
            value: average,
            color: colors?.[result] || colors.bad,
            observationDate: observation.observationDate,
          };
        }
        behaviorData[animalId] = item;
      });
    });

    // RESTRAINT
    const dates = eachDayOfInterval(dateRange);
    animals.forEach((animal) => {
      const animalId = animal._id;
      const item = behaviorData[animalId];
      dates.forEach((d) => {
        const date = format(new Date(d), 'dd-MM-yyyy', { locale: fr });
        item.restraint[date] = {
          date,
          labelValue: 'Non',
          value: 0.3,
          color: colors.good,
        };
      });

      behaviorData[animalId] = item;
    });

    observationsRestraint.forEach((observation) => {
      const date = format(new Date(observation.observationDate), 'dd-MM-yyyy', { locale: fr });
      observation.animals.forEach((animal) => {
        const animalId = animal._id;
        const item = behaviorData[animalId];

        item.restraint[date] = {
          ...item.restraint[date],
          labelValue: 'Oui',
          value: 1,
          color: colors.bad,
          observationDate: observation.observationDate,
        };

        behaviorData[animalId] = item;
      });
    });

    // COMPUTE BEHAVIOR DATA FOR CHARTS
    Object.entries(behaviorData).forEach(([animal, animalData]) => {
      Object.entries(animalData || {}).forEach(([key, data]) => {
        if (key !== 'name' && key !== 'behaviorDiversity') {
          behaviorData[animal][key] = Object.values(data);
        } else if (key === 'behaviorDiversity') {
          behaviorData[animal][key] = Object.values(data).map((dateCharacts) => {
            const arr = [];
            // COUNT VALUE DUPLICATE VALUE AND REMOVE IT
            dateCharacts.value.forEach((value) => {
              const find = arr.find((d) => d._id === value._id);
              if (!find) arr.push({ ...value, count: 1 });
              else { find.count += 1; }
            });
            // eslint-disable-next-line no-param-reassign
            dateCharacts.value = arr;
            return dateCharacts;
          });
        }
      });
    });

    Object.values(behaviorDiversityData).forEach((data) => {
      const arr = [];
      data.value.forEach((value) => {
        const find = arr.find((d) => d._id === value._id);
        if (!find) arr.push({ ...value, count: 1 });
        else { find.count += 1; }
      });
      // eslint-disable-next-line no-param-reassign
      data.value = arr;
      return data;
    });

    setBehaviorDiversity(Object.values(behaviorDiversityData));
    setAnimalsData(behaviorData);
  }, [filteredObservations, animals, dateRange]);

  const renderCharts = (data, animal) => (
    <div className={styles.containerCharts}>
      <div className={styles.chart}>
        <h3>Comportements individuels</h3>
        <RadarChart data={animalsRadarData[animal].behavior} unit="%" />
      </div>
      <div className={styles.chart}>
        <h3>Proximités</h3>
        <BarChart data={data.proximity} interval={0} dataKeyX="name" />
      </div>
      <div className={styles.chart}>
        <h3>WTP</h3>
        <LineChart domainY={[0, 4]} data={data.wtp} />
      </div>
      <div className={styles.chart}>
        <h3>Enrichissement</h3>
        <LineChart hideYAxisdomainY={[0, 1]} data={data.enrichment} />
      </div>
      <div className={styles.chart}>
        <h3>Contention</h3>
        <BarChart hideYAxisdomainY={[0, 1]} data={data.restraint} />
      </div>
    </div>
  );

  return (
    <>
      {behaviorDiversity && (
        <>
          <h3>Groupe</h3>
          <div className={`${styles.chart} ${styles.group}`}>
            <h3>Diversité des comportements</h3>
            {behaviorDiversity?.length === 0
              ? <p>Aucune donnée</p>
              : <DiversityBehaviorChart data={behaviorDiversity} />
            }
          </div>
        </>
      )}
      <></>
      {(animalsData && animalsRadarData) && (
      <>
        {Object.entries(animalsData).sort(([, a], [, b]) => a.name.localeCompare(b.name))
          .map(([animal, data]) => (
            <React.Fragment key={`bowl-${animal}`}>
              <h3 className={styles.animalName}>{data.name}</h3>
              <div className={styles.animal}>
                {renderCharts(data, animal)}
              </div>
            </React.Fragment>
          ))}
      </>
      )}
    </>
  );
};

export default DashboardBehavior;
