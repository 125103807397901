import {
  OBSERVATIONS_GET,
  OBSERVATION_STATS_SPECIE_GET,
  OBSERVATION_STATS_SPECIE_DOMAINS_GET,
  OBSERVATION_LIST_GET,
  OBSERVATION_LOADING,
  OBSERVATION_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  list: {},
  observations: [],
  stats: null,
  statsPerDomains: null,
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const observationReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case OBSERVATIONS_GET:
      updatedState = {
        ...state,
        observations: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== OBSERVATIONS_GET),
        error: null,
      };
      break;
    case OBSERVATION_LIST_GET:
      updatedState = {
        ...state,
        list: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== OBSERVATION_LIST_GET),
        error: null,
      };
      break;
    case OBSERVATION_STATS_SPECIE_GET:
      updatedState = {
        ...state,
        stats: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== OBSERVATION_STATS_SPECIE_GET),
        error: null,
      };
      break;
    case OBSERVATION_STATS_SPECIE_DOMAINS_GET:
      updatedState = {
        ...state,
        statsPerDomains: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== OBSERVATION_STATS_SPECIE_DOMAINS_GET),
        error: null,
      };
      break;
    case OBSERVATION_LOADING:
      updatedState = {
        ...state,
        error: null,
        observations: action.payload === OBSERVATIONS_GET ? [] : state.observations,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case OBSERVATION_ERROR:
      // eslint-disable-next-line no-case-declarations
      const message = action?.payload?.data?.error;
      updatedState = { ...state, error: message, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default observationReducer;
