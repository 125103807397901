import React, {
  useId, useState, useLayoutEffect,
} from 'react';
import {
  BarChart as RBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell,
} from 'recharts';
import styles from './bar-chart.module.scss';

const BarChart = ({
  data,
  dataKeyX = 'date',
  dataKeyBar = 'value',
  unit = '',
  interval = 'preserveEnd', // default value recharts
  domain = null,
  hideYAxis = false,
}) => {
  const pieID = useId();
  const [widthYAxis, setWidthYAxis] = useState();

  useLayoutEffect(() => {
    const axisY = document.getElementById(pieID)?.querySelector('.yAxis');
    if (axisY) {
      setWidthYAxis(Math.ceil(axisY.getBoundingClientRect().width));
    }
  }, []);

  const formatXAxis = (label) => (
    dataKeyX === 'date' ? `${label.replace(/-\d{4,4}$/, '')}` : label
  );

  const renderTooltip = (props) => {
    const { payload, label } = props;
    if (payload && payload.length) {
      const { name, value, labelValue } = payload[0].payload;
      return (
        <div className={styles.customTooltip}>
          <p>{label}</p>
          <p>{name ? <>{name} : </> : ''}{labelValue || `${value}${unit}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <RBarChart width={500} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey={dataKeyX}
        height={60}
        angle={-45}
        interval={interval}
        textAnchor="end"
        tickFormatter={formatXAxis}
      />
      <YAxis
        hide={hideYAxis}
        unit={unit}
        width={widthYAxis || 40}
        domain={domain || [0, 'auto']}
        allowDecimals={false}
      />
      <Tooltip isAnimationActive={false} content={renderTooltip} />
      <Bar
        dataKey={dataKeyBar}
        isAnimationActive={false}
        barSize={400 / data.length > 50 ? 50 : 400 / data.length}
      >
        {data.map((entry, i) => (
          <Cell key={`bar-${entry.key}-${entry.name}-${i}`} fill={entry.color} />
        ))}
      </Bar>
    </RBarChart>
  );
};

export default BarChart;
