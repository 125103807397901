import axios from 'axios';
import {
  postData,
  postFormData,
  getData,
  putData,
} from './index';
import {
  AUTH_SIGNIN,
  AUTH_SIGNUP,
  AUTH_GET_PROFILE,
  AUTH_PUT_PROFILE,
  AUTH_UPDATE_PROFILE_PICTURE,
  AUTH_DELETE_PROFILE_PICTURE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
} from './types';

export const signUp = async (dispatch, data) => {
  const url = '/auth/register';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  const user = response.data?.user;
  const token = response.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNUP,
      payload: { user, token },
    });
  }
  return response;
};

export const signIn = async (dispatch, data) => {
  const url = '/auth/login';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  const user = response.data?.user;
  const token = response.data?.token;

  if (user && token) {
    dispatch({
      type: AUTH_SIGNIN,
      payload: { user, token },
    });
  }
  return response;
};

export const forgotPassword = async (dispatch, data) => {
  const url = '/auth/forgot-password';
  const response = await postData(AUTH_ERROR, url, dispatch, data, false);
  return response;
};

export const resetPassword = async (dispatch, data) => {
  const url = `/auth/reset-password/${data.token}`;
  const response = await postData(AUTH_ERROR, url, dispatch, { password: data.password }, false);
  return response;
};

export const logout = (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
  });
};

export const completeUserProfile = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/users/register/${data.token}`;
  const { token, ...udpdatedData } = data;
  let response;
  try {
    const config = {};
    response = await axios.put(url, udpdatedData, config);
  } catch (err) {
    console.log(err);
  }
  return response;
};

export const updateProfilePicture = async (dispatch, data) => {
  const url = '/users/picture';
  dispatch({
    type: AUTH_LOADING,
  });

  const response = await postFormData(AUTH_ERROR, true, url, dispatch, data);
  if (response.data) {
    dispatch({
      type: AUTH_UPDATE_PROFILE_PICTURE,
      payload: response.data,
    });
  }
  return response;
};

export const updateDeleteProfilePicture = async (dispatch, id) => {
  const url = `/users/${id}`;
  dispatch({
    type: AUTH_LOADING,
  });

  const response = await putData(AUTH_ERROR, url, dispatch, { picture: null }, true);
  if (response.data?.user) {
    dispatch({
      type: AUTH_DELETE_PROFILE_PICTURE,
      payload: response.data.user,
    });
  }
  return response;
};

export const getProfileAction = async (dispatch) => {
  const url = '/users/profile';
  dispatch({
    type: AUTH_LOADING,
  });
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: AUTH_GET_PROFILE,
      payload: response.data,
    });
  }
};

export const updateProfile = async (dispatch, data) => {
  const url = `/users/profile`;
  dispatch({
    type: AUTH_LOADING,
    payload: AUTH_PUT_PROFILE,
  });
  const response = await putData(AUTH_ERROR, url, dispatch, data, true);
  console.log(response);
  if (response.data?.user) {
    dispatch({
      type: AUTH_PUT_PROFILE,
      payload: response.data.user,
    });
  }
  return response;
};

export const resetError = (dispatch) => {
  dispatch({
    type: AUTH_RESET_ERROR,
  });
};
