import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  addMonths,
  addWeeks,
  startOfWeek,
  getDaysInMonth,
  startOfMonth,
  addDays,
  format,
  parse,
  getWeek,
  isValid,
} from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { fr } from 'date-fns/locale';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import styles from './select-date-range.module.scss';

const getDatesOfMonth = (date) => {
  const start = startOfMonth(date);
  const numberOfDays = getDaysInMonth(date);
  const dates = [];
  let currentDate = start;

  for (let i = 0; i < numberOfDays; i += 1) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

const getDatesOfWeek = (date) => {
  const start = startOfWeek(date, { weekStartsOn: 1 });
  const numberOfDays = 7;
  const dates = [];
  let currentDate = start;

  for (let i = 0; i < numberOfDays; i += 1) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

const SelectDateRange = ({
  handleSetRange,
  range,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dates, setDates] = useState([]);

  const currentDate = useMemo(() => {
    const date = parse(searchParams.get('date'), 'dd-MM-yyyy', new Date(), { locale: fr });
    return isValid(date) ? date : new Date();
  }, [searchParams.get('date')]);

  useEffect(() => {
    if (dates.length < 2) return;
    handleSetRange(dates[0], dates[dates.length - 1]);
  }, [dates]);

  useEffect(() => {
    if (range === 'month') {
      setDates(getDatesOfMonth(currentDate));
    } else if (range === 'week') {
      setDates(getDatesOfWeek(currentDate));
    }
  }, [currentDate, range]);

  const shouldCancelDate = (date) => {
    try {
      const dateDetails = {
        month: date.getMonth(),
        year: date.getFullYear(),
        week: getWeek(date, { startOfWeek: 1, locale: fr }),
      };
      const todayDetails = {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        week: getWeek(new Date(), { startOfWeek: 1, locale: fr }),
      };
      let canceled = false;

      if (
        dateDetails.year > todayDetails.year
        || (range === 'week' && dateDetails.year >= todayDetails.year && dateDetails.week > todayDetails.week)
        || (range === 'month' && dateDetails.year >= todayDetails.year && dateDetails.month > todayDetails.month)
      ) {
        canceled = true;
      }

      return canceled;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleChangeRange = useCallback((dir = 'next') => {
    let updateDate = currentDate;
    if (range === 'week') {
      updateDate = addWeeks(currentDate, dir === 'next' ? 1 : -1);
    } else if (range === 'month') {
      updateDate = addMonths(currentDate, dir === 'next' ? 1 : -1);
    }

    // if (dir === 'next') {
    //   canceled = shouldCancelDate(updateDate);
    // }

    const canceled = shouldCancelDate(updateDate);

    if (!canceled) {
      const params = new URLSearchParams(searchParams);
      const strDate = format(updateDate, 'dd-MM-yyyy', { locale: fr });
      params.set('date', strDate);
      setSearchParams(params);
    }
  }, [searchParams, currentDate, range]);

  const shouldDisabledNextButton = useMemo(() => {
    let disabled = false;
    if (range === 'week') disabled = shouldCancelDate(addWeeks(currentDate, 1));
    else if (range === 'month') disabled = shouldCancelDate(addMonths(currentDate, 1));

    return disabled;
  }, [range, currentDate]);

  return (
    <div className={styles.selectDateRange}>
      <button onClick={() => handleChangeRange('prev')}>
        <FiChevronLeft size={20} />
      </button>
      {range === 'month' && (
        <h3>{format(currentDate, 'LLLL yyyy', { locale: fr })}</h3>
      )}
      {range === 'week' && (
      <h3>Semaine {format(currentDate, 'ww', { locale: fr })}</h3>
      )}
      <button className={shouldDisabledNextButton ? 'disabled' : ''} disabled={shouldDisabledNextButton} onClick={() => handleChangeRange('next')}>
        <FiChevronRight size={20} />
      </button>
    </div>
  );
};

export default SelectDateRange;
