// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_UPDATE_PROFILE_PICTURE = 'AUTH_UPDATE_PROFILE_PICTURE';
export const AUTH_DELETE_PROFILE_PICTURE = 'AUTH_DELETE_PROFILE_PICTURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';

// ACTIONS SPECIES

export const SPECIE_GET = 'SPECIE_GET';
export const SPECIE_LIST_GET = 'SPECIES_GET';
export const SPECIE_PUT = 'SPECIE_PUT';
export const SPECIE_LOADING = 'SPECIE_LOADING';
export const SPECIE_ERROR = 'SPECIE_ERROR';

// ACTIONS OBSERVATIONS
export const OBSERVATIONS_GET = 'OBSERVATIONS_GET';
export const OBSERVATION_STATS_SPECIE_GET = 'OBSERVATION_STATS_SPECIE_GET';
export const OBSERVATION_STATS_SPECIE_DOMAINS_GET = 'OBSERVATION_STATS_SPECIE_DOMAINS_GET';
export const OBSERVATION_LIST_GET = 'OBSERVATION_LIST_GET';
export const OBSERVATION_LOADING = 'OBSERVATION_LOADING';
export const OBSERVATION_ERROR = 'OBSERVATION_ERROR';

// ACTIONS ANIMALS

export const ANIMALS_GET = 'ANIMALS_GET';
export const ANIMAL_GET = 'ANIMAL_GET';
export const ANIMAL_LOADING = 'ANIMAL_LOADING';
export const ANIMAL_ERROR = 'ANIMAL_ERROR';
